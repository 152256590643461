<template>
  <div class="maintenance-container">
    <h1>We'll Be Back Soon</h1>
    <p>Our site is currently undergoing scheduled maintenance. We appreciate your patience.</p>
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
};
</script>

<style scoped>
.maintenance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f2f2f2;
  color: #333;
}
h1 {
  font-size: 3em;
  color: #3498db;
  margin-bottom: 20px;
}
p {
  font-size: 1.2em;
  margin-bottom: 20px;
}
.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

