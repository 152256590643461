<template>
    <!-- Navbar start -->
    <nav class="w-screen fixed bg-opacity-90 bg-white p-4 lg:px-20 flex justify-between z-50">
		<!-- Logo start -->
		<div class="h-full flex flex-col justify-center">
			<p class="tracking-widest font-semibold">EMONI</p>
			<p class="tracking-widest font-light"> Slim omgaan met energie </p>
		</div>
		<!-- Logo end -->

		<!-- Toggler start -->
		<button role="menubar" class="md:hidden" @click="navToggle()">
			<i class="fa-solid fa-bars text-2xl"></i>
		</button>
		<!-- Toggler end -->

		<!-- Menu content start -->
		<div class="absolute left-0 right-0 translate-y-16 bg-opacity-90 bg-white md:bg-none md:bg-opacity-0 shadow hidden md:flex flex-col gap-7 items-center p-4 md:flex-row md:static md:shadow-none md:translate-y-0" role="menu" aria-expanded="false">
			<!-- Links start -->
			<button style="cursor:pointer;" @click="$emit('scrollTo', 'main-section')" role="menuitem">
				Home
			</button>

			<button style="cursor:pointer;" @click="$emit('scrollTo', 'about-section')" role="menuitem">
				Over ons
			</button>

			<button style="cursor:pointer;" @click="$emit('scrollTo', 'service-section')" role="menuitem">
				Diensten
			</button>

			<button style="cursor:pointer;" @click="$emit('scrollTo', 'portfolio-section')" role="menuitem">
				Portfolio
			</button>

			<button style="cursor:pointer;" @click="$emit('scrollTo', 'contact-section')" role="menuitem">
				Contact
			</button>
			<!-- Links end -->
		</div>
		<!-- Menu content end -->
	</nav>
    <!-- Navbar end -->
</template>

<script>

export default {
    methods: {
			// Toogle navbar mode 
      navToggle() {
			let menu = document.querySelector("[role='menu']");
			let isExpanded = menu.getAttribute('aria-expanded');
			menu.setAttribute('aria-expanded', !isExpanded);
			menu.classList.toggle('hidden');
			menu.classList.toggle('flex');
		},
		//scrollToBottom() {
		//	console.log("Clicked!");
		//}
    }
}
</script>

<style>

</style>
